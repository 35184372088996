import DownloadButton from "app/components/DownloadButton";
import { WebMessageProps } from "app/components/Messages/MessageListItem";
import { useDownloadAttachment } from "app/utils/hooks";
import { unescapeHtml } from "app/utils/common";
import { h } from "preact";
import { urlify } from "./TextMessage";

const AudioMessage: preact.FunctionalComponent<WebMessageProps> = (props) => {
    const { webMessage } = props;
    const path = webMessage?.audio?.path;
    const name = webMessage?.audio?.filename;
    const caption = unescapeHtml(webMessage?.audio?.caption);
    const downloadLink = useDownloadAttachment(path, name);
    if (!webMessage) return null;
    return (
        <div className="media-message-wrapper">
            {downloadLink && <DownloadButton {...downloadLink} />}
            <video controls className="audio-message">
                <source src={webMessage.audio?.path} type={webMessage.audio?.mimeType} />
            </video>
            {caption && <div>{urlify(caption)}</div>}
        </div>
    );
};

export default AudioMessage;
