import { Storage } from "app/types";
import { getParentHostname } from "app/utils/helpers";
import { h, Component, createContext } from "preact";
import { v4 as uuid } from "uuid";
import Store, { KeyValue } from "./Store";

export const initialState: Storage = {
    localVisitorId: uuid(),
    widgetStatus: {
        undocked: false,
        hasOpenedTheChat: false,
        minimized: true,
    },
    ip: "0.0.0.0",
    channelSettings: {
        brandColor: "transparent",
    },
};

const localStorageKey = `store-${getParentHostname()}`;
const dontPersist: string[] = ["messages", "typing", "loading", "alerts", "unread", "noMoreMessages"];

export const store = new Store(initialState, { localStorageKey, dontPersist });

if (process.env.NODE_ENV === "development") {
    store.on("change", (_state: KeyValue, _prevState: KeyValue, partialState: KeyValue) => {
        // eslint-disable-next-line no-console
        console.log("%cstore.setState %c%o", "color: blue", "color: initial", partialState);
    });
}

export interface StorageContext extends Storage {
    dispatch: (partialState: Partial<Storage>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StoreContext = createContext<StorageContext>({ ...initialState, dispatch: () => 0 });

export class Provider extends Component {
    state = { ...store.state, dispatch: store.setState.bind(store) };

    handleStoreChange = () => {
        this.setState({ ...store.state });
    };

    componentDidMount() {
        store.on("change", this.handleStoreChange);
    }

    componentWillUnmount() {
        store.off("change", this.handleStoreChange);
    }

    render() {
        return <StoreContext.Provider value={this.state}>{this.props.children}</StoreContext.Provider>;
    }
}

export default store;
