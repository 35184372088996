import { Provider as StoreProvider } from "app/store";
import { SocketIOProvider } from "app/utils/use-socketio";
import { h } from "preact";
import { AppContainer } from "./AppContainer";

const SocketConnector = () => {
    const socketUrl: string = process.env.SERVER_SOCKET_URL || "";
    const token = window.location.hash?.substring(1);

    return (
        <StoreProvider>
            <SocketIOProvider url={socketUrl} opts={{ query: `token=${token}&type=v`, secure: true }}>
                <AppContainer token={token} />
            </SocketIOProvider>
        </StoreProvider>
    );
};

export default SocketConnector;
