export const unescapeHtml = (html?: string): string | undefined => {
    if (!html) return html;
    return (
        html
            // eslint-disable-next-line quotes
            .replace(/&quot;/g, '"')
            .replace(/&#39;/g, "'")
            .replace(/&#x3A;/g, ":")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&amp;/g, "&")
    );
};
