import { useDownloadAttachment } from "app/utils/hooks";
import { h } from "preact";
import { WebMessageProps } from "app/components/Messages/MessageListItem";
import { unescapeHtml } from "app/utils/common";
import { urlify } from "./TextMessage";

const DocumentMessage: preact.FunctionalComponent<WebMessageProps> = (props) => {
    const { webMessage, myMessage } = props;
    const path = webMessage?.document?.path;
    const name = webMessage?.document?.filename;
    const caption = unescapeHtml(webMessage?.document?.caption);
    const downloadLink = useDownloadAttachment(path, name);
    if (!webMessage) return null;
    return (
        <div>
            <div className="document-message">
                <div className="download-icon">
                    <svg
                        className={`svg-document-icon ${!myMessage ? "white" : ""}`}
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        aria-hidden="true"
                        focusable="false"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 0 0 174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z" />
                    </svg>
                </div>
                <div className="filename" title={webMessage.document?.filename}>
                    {webMessage.document?.filename}
                </div>
                <a
                    className={`download-link ${!myMessage ? "white" : ""}`}
                    download={downloadLink?.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={downloadLink?.url}
                    title="Download"
                >
                    <svg className={`svg-download-icon ${!myMessage ? "white" : ""}`} viewBox="0 0 20 20">
                        <path
                            fill-rule="evenodd"
                            d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </a>
            </div>
            {caption && <div style={{ marginTop: "0.5rem" }}>{urlify(caption)}</div>}
        </div>
    );
};

export default DocumentMessage;
