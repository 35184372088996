import {
    AudioMessage,
    DocumentMessage,
    ImageMessage,
    StickerMessage,
    TextMessage,
    VideoMessage,
} from "app/components/Message";
import { StoreContext } from "app/store";
import { Message, WebChannel, WebMessage } from "app/types";
import classNames from "classnames";
import { h } from "preact";
import { useContext } from "preact/hooks";
export interface WebMessageProps {
    webMessage?: WebMessage;
    myMessage: boolean;
}

interface MessageListItemProps {
    message: Message;
    me: string;
    brandColor: string;
    isSameParticipant: boolean;
}

const messageComponent = new Map([
    ["text", TextMessage],
    ["image", ImageMessage],
    ["document", DocumentMessage],
    ["video", VideoMessage],
    ["audio", AudioMessage],
    ["sticker", StickerMessage],
]);

const MessageListItem: preact.FunctionalComponent<MessageListItemProps> = (props) => {
    const { message, me, brandColor, isSameParticipant } = props;
    const myMessage = message.sender === me;

    const store = useContext(StoreContext);
    const { channelSettings } = store;

    const logo = channelSettings?.logo ?? "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y";

    const msgType = message.web?.type;

    const Component = messageComponent.get(msgType ?? "");
    if (!Component || !msgType) return null;

    return (
        <div key={message.localMessageId} className={classNames("message", { right: message.sender === me })}>
            {!myMessage && !isSameParticipant ? (
                <img className="user-avatar" src={logo} alt="" width="30px" height="30px" />
            ) : (
                <div style={{ width: 30, margin: 5 }} />
            )}
            <div className="message-container">
                <div className="message-body-wrapper">
                    <div
                        className="message-body"
                        style={{
                            backgroundColor: myMessage ? "#fff" : brandColor,
                            borderColor: myMessage ? "#dedede" : brandColor,
                            borderWidth: msgType === "sticker" ? 0 : 1,
                            borderStyle: myMessage ? "solid" : "none",
                            color: myMessage ? "#333" : "#fff",
                            padding: "0.5rem 0.75rem",
                        }}
                    >
                        <Component myMessage={myMessage} webMessage={message.web} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageListItem;
