import NewMessageForm from "app/components/NewMessageForm";
import { StoreContext } from "app/store";
import { Message as IMessage, WebChannel } from "app/types";
import classNames from "classnames";
import { Fragment, h, JSX } from "preact";
import { StateUpdater, useContext, useMemo, useState } from "preact/hooks";
import { v4 as uuid } from "uuid";
import { getWelcomeMessage } from "../Messages/Messages";
import { default as ConversationMessage } from "../Messages/MessageListItem";

export interface ContactMessage {
    id: string;
    sender: string;
    text?: string;
    input?: {
        name: string;
        required: boolean;
        pattern?: string;
        type?: string;
        value?: string;
    };
    button?: boolean;
}

interface ContactInfo {
    getName: boolean;
    getEmail: boolean;
    getMobile: boolean;
    visitorIntroText?: string;
}

const contactInfoList = ({ getName, getEmail, getMobile, visitorIntroText }: ContactInfo): ContactMessage[] => {
    const info: ContactMessage[] = [];
    if (getName) {
        info.push(
            {
                id: uuid(),
                sender: "user",
                text: visitorIntroText || "Please give us your basic details to continue",
            },
            { id: uuid(), sender: "user", input: { name: "name", required: true, pattern: "[a-zA-Z0-9\\s]+" } }
        );
    }

    if (getEmail) {
        info.push({ id: uuid(), sender: "user", input: { name: "email", type: "email", required: true } });
    }

    if (getMobile) {
        info.push({ id: uuid(), sender: "user", input: { name: "mobile", required: true, pattern: "[+0-9]+" } });
    }

    return info.reverse();
};

export interface ContactProps {
    disabled: boolean;
    setFirstMessage: StateUpdater<IMessage | null>;
    onSubmitContact: (contactInfo: Record<string, string>) => void;
}

const Contact: preact.FunctionalComponent<ContactProps> = (props) => {
    const { disabled, setFirstMessage, onSubmitContact } = props;

    const [messages, setMessages] = useState<ContactMessage[]>([]);
    const [contactInfo, setContactInfo] = useState<Record<string, string>>({});

    const store = useContext(StoreContext);
    const { visitor, channelSettings: cs, localVisitorId, decodedToken } = store;

    const { accountId, channelId } = decodedToken ?? {};
    const channelSettings = cs as WebChannel;
    const { brandColor, welcomeMessage: welcomeMsgText } = channelSettings;

    const me = visitor?.id ?? localVisitorId;

    const formMessages = useMemo(() => {
        const requiredInfo = Object.entries(channelSettings).reduce((acc, [key, value]) => {
            if (key === "getName" && value === true) {
                acc.getName = true;
            }
            if (key === "getEmail" && value === true) {
                acc.getEmail = true;
            }
            if (key === "getMobile" && value === true) {
                acc.getMobile = true;
            }
            acc.visitorIntroText = channelSettings.visitorIntroText;
            return acc;
        }, {} as ContactInfo);

        return contactInfoList(requiredInfo);
    }, [channelSettings]);

    const onSubmit = (e: JSX.TargetedEvent<HTMLFormElement, Event>) => {
        e.preventDefault();
        onSubmitContact(contactInfo);
    };

    const onFirstMessage = (text?: string) => {
        if (!accountId || !channelId) return;

        setFirstMessage({
            localMessageId: uuid(),
            sender: me,
            visitorId: visitor?.id,
            web: {
                type: "text",
                text: { body: text ?? "" },
            },
            accountId,
            channelId,
            channelType: "web",
        });

        setMessages((prev) => [
            { id: uuid(), sender: "user", button: true },
            ...formMessages,
            { id: uuid(), sender: me, text },
            ...prev,
        ]);
    };

    if (!accountId || !channelId) return null;

    const welcomeMessage = getWelcomeMessage(channelId, accountId, welcomeMsgText);

    return (
        <Fragment>
            <div className="messages">
                <form onSubmit={onSubmit}>
                    {[...messages].reverse().map((msg, index) => {
                        const nextMsg = messages[index + 1];
                        const isSameParticipant = msg.sender === nextMsg?.sender;
                        return (
                            <ContactMessageFooter
                                message={msg}
                                me={me}
                                brandColor={brandColor}
                                isSameParticipant={isSameParticipant}
                                setContactInfo={setContactInfo}
                                contactInfo={contactInfo}
                            />
                        );
                    })}
                </form>
                <ConversationMessage
                    message={welcomeMessage}
                    me={me}
                    brandColor={brandColor}
                    isSameParticipant={false}
                />
            </div>
            <NewMessageForm onSubmit={onFirstMessage} disabled={disabled} brandColor={brandColor} />
        </Fragment>
    );
};

interface ContactMessageProps {
    message: ContactMessage;
    me: string;
    brandColor: string;
    isSameParticipant: boolean;
    contactInfo?: Record<string, string>;
    setContactInfo?: StateUpdater<Record<string, string>>;
}

const ContactMessageFooter: preact.FunctionalComponent<ContactMessageProps> = (props) => {
    const { message, me, brandColor, isSameParticipant, contactInfo = {}, setContactInfo } = props;
    const { visitor } = useContext(StoreContext);
    const inputName = message.input?.name;
    const iv = inputName ? new Map(Object.entries(contactInfo)).get(inputName) : "";
    const inputValue = inputName === "mobile" && !iv ? visitor?.telCode : iv;

    const onChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
        if (!inputName || !setContactInfo) return;
        const value = (e.target as HTMLInputElement)?.value ?? "";
        setContactInfo((prev) => ({ ...prev, [inputName]: value }));
    };

    const myMessage = message.sender === me;

    return (
        <div key={message.id} className={classNames("message", { right: message.sender === me })}>
            {message.sender !== me && !isSameParticipant ? (
                <img
                    className="user-avatar"
                    src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                />
            ) : (
                <div style={{ width: 30, margin: 5 }} />
            )}
            <div className="message-container">
                <div className="message-body-wrapper">
                    <div
                        className="message-body"
                        style={{
                            backgroundColor: myMessage || message.input ? "#fff" : brandColor,
                            borderColor: myMessage || message.input ? "#dedede" : brandColor,
                            borderWidth: 1,
                            borderStyle: myMessage || message.input ? "solid" : "none",
                            color: myMessage || message.input ? "#333" : "#fff",
                        }}
                    >
                        {message.text ? <div>{message.text}</div> : null}
                        {message.input ? (
                            <input
                                type="text"
                                style={{
                                    border: "none",
                                    fontSize: 16,
                                    color: "#333",
                                    maxWidth: "100%",
                                }}
                                {...message.input}
                                placeholder={inputName === "mobile" ? "Mobile with country code" : `Your ${inputName}`}
                                onChange={onChange}
                                value={inputValue}
                            />
                        ) : null}
                        {message.button ? (
                            <button
                                type="submit"
                                style={{
                                    color: "#fff",
                                    fontSize: 16,
                                }}
                            >
                                Submit
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
