import { useDownloadAttachment } from "app/utils/hooks";
import { h } from "preact";
import { WebMessageProps } from "app/components/Messages/MessageListItem";
import DownloadButton from "app/components/DownloadButton";
import { unescapeHtml } from "app/utils/common";
import { urlify } from "./TextMessage";

const ImageMessage: preact.FunctionalComponent<WebMessageProps> = (props) => {
    const { webMessage } = props;
    const path = webMessage?.image?.path;
    const name = webMessage?.image?.filename;
    const caption = unescapeHtml(webMessage?.image?.caption);
    const downloadLink = useDownloadAttachment(path, name);
    if (!webMessage) return null;
    return (
        <div className="media-message-wrapper">
            {downloadLink && <DownloadButton {...downloadLink} />}
            <a href={webMessage.image?.path} target="_blank" rel="noopener noreferrer">
                <img src={webMessage.image?.path} alt={caption} className="image-message" />
            </a>
            {caption && <div>{urlify(caption)}</div>}
        </div>
    );
};

export default ImageMessage;
