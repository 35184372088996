import { StoreContext } from "app/store";
import { Message as IMessage } from "app/types";
import preact, { Fragment, h } from "preact";
import { useContext } from "preact/hooks";
import Message from "./MessageListItem";

interface MessageListProps {
    messages: IMessage[];
    isLoading: boolean;
}

const MessageList: preact.FunctionalComponent<MessageListProps> = (props) => {
    const { messages, isLoading } = props;

    const { visitor, channelSettings, localVisitorId } = useContext(StoreContext);

    const visitorId = visitor?.id;
    const me = visitorId ?? localVisitorId;
    const brandColor = channelSettings?.brandColor || "transparent";

    return (
        <Fragment>
            {messages.map((message: IMessage, index: number) => {
                const nextMessage = messages[index + 1];
                const isSameParticipant = message.sender === nextMessage?.sender;
                return (
                    <Message message={message} me={me} brandColor={brandColor} isSameParticipant={isSameParticipant} />
                );
            })}
            {isLoading ? (
                <div class="messages-loading">
                    <span>Loading...</span>
                </div>
            ) : null}
        </Fragment>
    );
};

export default MessageList;
