export const browserNameVersion = (): string[] => {
    const ua = navigator.userAgent;
    let tem = null;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return ["IE", tem[1] || ""];
    }
    if (M[1] === "Chrome") {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
        if (tem != null) {
            return tem.slice(1).join(" ").replace("OPR", "Opera").split(" ");
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
    tem = ua.match(/version\/(\d+)/i);
    if (tem != null) {
        M.splice(1, 1, tem[1]);
    }
    return M;
};

export const timeZone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getIp = async (): Promise<string> => {
    return await fetch("https://www.cloudflare.com/cdn-cgi/trace")
        .then((res) => res.text())
        .then((res) => {
            const r = res.split("\n");
            return (r.find((i) => i.includes("ip=")) || "").replace("ip=", "");
        });
};
