import { WidgetContext } from "app/components/App/App";
import Contact from "app/components/Contact";
import Messages from "app/components/Messages";
import { StoreContext } from "app/store";
import { Conversation as IConversation, Message, Visitor, WebChannel } from "app/types";
import { ActionEvents, socketEmit, SocketEvents, useSocket } from "app/utils/use-socketio";
import { h } from "preact";
import { useContext, useEffect, useMemo, useState } from "preact/hooks";
import ConversationHeader from "./ConversationHeader";
import MinimizedConversation from "./MinimizedConversation";
import { v4 as uuid } from "uuid";

type MessageCallback = (conv: IConversation, msg: Message) => void;

const Conversation: preact.FunctionalComponent = () => {
    const [conversation, setConversation] = useState<IConversation | null>(null);
    const [firstMessage, setFirstMessage] = useState<Message | null>(null);
    const [contactMessage, setContactMessage] = useState<Message | null>(null);
    const [messages, setMessages] = useState<Message[]>([]);

    const { minimized, onRestore, onMinimize, isConnected } = useContext(WidgetContext);
    const store = useContext(StoreContext);

    const { visitor, channelSettings: cs, openConversation, localVisitorId, dispatch, decodedToken } = store;

    const channelSettings = cs as WebChannel;

    const { brandColor, logo, widgetHeadline, statusTagLine } = channelSettings;
    const { channelId, accountId } = decodedToken ?? {};

    const me = visitor?.id ?? localVisitorId;

    const onJoinConversation = (conv?: IConversation) => {
        if (!conv) return;
        setConversation(conv);
    };

    const onActionEvent = (action: string, visitor: Visitor) => {
        if (action === ActionEvents.AddContact) {
            dispatch({ visitor });
            if (firstMessage) {
                const messageWithContactId: Message = { ...firstMessage, contactId: visitor?.contactId };
                setMessages((prev) => [messageWithContactId, ...prev]);
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                onSendFirstMessage(messageWithContactId);
            }
        }
    };

    const { socket } = useSocket(SocketEvents.JoinConversation, onJoinConversation);
    useSocket(SocketEvents.ActionEvent, onActionEvent);

    const onLoadMoreMessages = (nextCursor: string) => {
        socketEmit(socket, SocketEvents.LoadMessages, me, { limit: 10, nextCursor });
    };

    const onSendMessage = (message: Message, cb?: MessageCallback) => {
        socketEmit(socket, SocketEvents.Message, message, cb);
    };

    const onSendFirstMessage = (message: Message) => {
        const callback: MessageCallback = (conv, msg) => {
            if (!conv || !msg || !contactMessage) return;

            if (!openConversation) {
                dispatch({ openConversation: conv });

                const vId = store?.visitor?.id;
                socketEmit(socket, SocketEvents.JoinConversation, vId);
            }

            const contactMessageWithContactId: Message = { ...contactMessage, contactId: visitor?.contactId };
            setMessages((prev) => [contactMessageWithContactId, ...prev]);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            onSendMessage(contactMessageWithContactId);
        };

        socketEmit(socket, SocketEvents.Message, message, callback);
    };

    const onSubmitContact = (contactInfo: Record<string, string>) => {
        if (!accountId || !channelId) return;

        const contactMsgText = Object.entries(contactInfo)
            .map((c) => c.join(": "))
            .join("\n");

        const contactMessage: Message = {
            localMessageId: uuid(),
            sender: me,
            visitorId: visitor?.id,
            web: {
                type: "text",
                text: { body: contactMsgText },
            },
            accountId,
            channelId,
            channelType: "web",
        };

        setContactMessage(contactMessage);

        socketEmit(socket, SocketEvents.ActionEvent, ActionEvents.AddContact, visitor, contactInfo);
    };

    useEffect(() => {
        const vId = store?.visitor?.id;

        if (!isConnected || !vId || !openConversation) return;

        if (minimized) {
            socketEmit(socket, SocketEvents.LeaveConversation, vId);
        } else {
            socketEmit(socket, SocketEvents.JoinConversation, vId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, minimized]);

    useEffect(() => {
        if (!conversation || minimized) return;

        socketEmit(socket, SocketEvents.LoadMessages, me);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversation?.id, minimized]);

    const showContactForm = useMemo(() => {
        const { name, email, mobile } = visitor || {};
        const { getName, getEmail, getMobile, allowAnonymousChat } = channelSettings;

        if (allowAnonymousChat || openConversation != null) {
            return false;
        }

        const hasName = getName ? Boolean(name) : true;
        const hasEmail = getEmail ? Boolean(email) : true;
        const hasMobile = getMobile ? Boolean(mobile) : true;

        if (hasName && hasEmail && hasMobile) {
            return false;
        }

        return true;
    }, [openConversation, channelSettings, visitor]);

    if (minimized) {
        return <MinimizedConversation onRestore={onRestore} />;
    }

    return (
        <div className="conversation-container">
            <ConversationHeader
                brandColor={brandColor}
                logo={logo}
                headLine={widgetHeadline}
                status={statusTagLine}
                onMinimize={onMinimize}
            />
            {showContactForm ? (
                <Contact
                    onSubmitContact={onSubmitContact}
                    disabled={Boolean(firstMessage)}
                    setFirstMessage={setFirstMessage}
                />
            ) : (
                <Messages
                    messages={messages}
                    setMessages={setMessages}
                    onSend={onSendMessage}
                    onLoadMore={onLoadMoreMessages}
                />
            )}
        </div>
    );
};

export default Conversation;
