import "preact/debug";
import "./style/index.css";

import * as preact from "preact";
import * as hooks from "preact/hooks";

import App from "app/components/App";

export default App;

// allows users to play with preact in the browser developer console
global.preact = { ...preact, ...hooks };
