import { WebMessageType } from "app/types";

interface MyDocument extends Document {
    msHidden?: boolean;
    webkitHidden?: boolean;
}

const audioMimeTypes = ["audio/aac", "audio/mp4", "audio/amr", "audio/mpeg", "audio/ogg"];
const imageMimeTypes = ["image/jpeg", "image/png"];
const stickerMimeTypes = ["image/webp"];
const videoMimeTypes = ["video/mp4", "video/3gpp"];

export const getFileType = (mimeType: string): Exclude<WebMessageType, "location"> => {
    if (audioMimeTypes.includes(mimeType)) {
        return "audio";
    }
    if (imageMimeTypes.includes(mimeType)) return "image";
    if (stickerMimeTypes.includes(mimeType)) return "sticker";
    if (videoMimeTypes.includes(mimeType)) return "video";
    return "document";
};

export const visibility = (() => {
    if (typeof document.hidden !== "undefined") {
        return {
            get hidden() {
                return document.hidden;
            },
            addListener: (f: EventListenerOrEventListenerObject) =>
                document.addEventListener("visibilitychange", f, false),
            removeListener: (f: EventListenerOrEventListenerObject) =>
                document.removeEventListener("visibilitychange", f, false),
        };
    }

    if (typeof (document as MyDocument).msHidden !== "undefined") {
        return {
            get hidden() {
                return (document as MyDocument).msHidden;
            },
            addListener: (f: EventListenerOrEventListenerObject) =>
                document.addEventListener("msvisibilitychange", f, false),
            removeListener: (f: EventListenerOrEventListenerObject) =>
                document.removeEventListener("msvisibilitychange", f, false),
        };
    }

    if (typeof (document as MyDocument).webkitHidden !== "undefined") {
        return {
            get hidden() {
                return (document as MyDocument).webkitHidden;
            },
            addListener: (f: EventListenerOrEventListenerObject) =>
                document.addEventListener("webkitvisibilitychange", f, false),
            removeListener: (f: EventListenerOrEventListenerObject) =>
                document.removeEventListener("webkitvisibilitychange", f, false),
        };
    }

    return {
        hidden: true,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        addListener: () => {},
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        removeListener: () => {},
    };
})();

export const kebabCase = (string: string) => {
    return string
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/\s+/g, "-")
        .toLowerCase();
};

export const getParentHostname = () => {
    const isInIframe = parent !== window;
    let hostname = "";

    if (isInIframe) {
        hostname = new URL(document.referrer).hostname;
    }

    return kebabCase(hostname);
};
