import { SocketEvents } from "./useSocket";

export * from "./context";
export * from "./provider";
export * from "./useSocket";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const socketEmit = (socket: SocketIOClient.Socket, eventKey: SocketEvents, ...args: any[]) => {
    console.debug("emit", eventKey, args);
    return socket.emit(eventKey, ...args);
};
