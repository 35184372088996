import Conversation from "app/components/Conversation";
import classNames from "classnames";
import { h } from "preact";
import { useContext } from "preact/hooks";
import { StoreContext } from "app/store";
import { WidgetContext } from "../App/App";
import { WebChannel } from "app/types";

const Home: preact.FunctionalComponent = ({ ...props }) => {
    const { minimized } = useContext(WidgetContext);
    const { channelSettings: cs } = useContext(StoreContext);
    const channelSettings = cs as WebChannel;
    const isPlacedLeft = channelSettings?.widgetPosition?.placement === "left" || false;

    return (
        <div className="body-container" style={{ flexDirection: isPlacedLeft ? "row" : "row-reverse" }}>
            <div className={classNames("widget-container", { minimized }, { maximized: !minimized })}>
                <div className={classNames("main-container", { minimized }, { maximized: !minimized })}>
                    <Conversation {...props} />
                </div>
            </div>
        </div>
    );
};

export default Home;
