export interface WidgetStatus {
    undocked: boolean;
    hasOpenedTheChat: boolean;
    minimized: boolean;
}

interface InitialChannelSettings {
    brandColor: string;
    logo?: string;
}

type ChannelSettings = InitialChannelSettings | WebChannel;

export interface VisitorToken {
    id: string;
    accId: string;
    host: string;
    iat: number;
}

export interface DecodedToken {
    channelId: string;
    accountId: string;
    host: string;
    iat: number;
}

export interface Storage {
    localVisitorId: string;
    widgetStatus: WidgetStatus;
    ip: string;
    decodedToken?: DecodedToken;
    channelSettings?: ChannelSettings;
    visitor?: Visitor;
    openConversation?: Conversation;
}

export interface WidgetInfo {
    id: string;
    accId: string;
    host: string;
    iat: number;
}

export interface PageInfo {
    url: string;
    title: string;
    time: Date;
}

export interface VisitorConnectInfo {
    visitor: Visitor;
    conversation?: Conversation;
    channelSettings: ChannelSettings;
}

export type BasicVisitorInfo = Pick<
    Visitor,
    "ip" | "timezone" | "userAgent" | "browser" | "browserVersion" | "localVisitorId"
>;

export interface Account {
    id?: string;
    name: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface VisitedPage {
    url: string;
    title: string;
    time: string;
}

export enum PresenceOptions {
    ONLINE = "ONLINE",
    OFFLINE = "OFFLINE",
    AWAY = "AWAY",
}

export interface Visitor {
    id?: string;
    accountId: string;
    account?: Account;
    channelId: string;
    channel?: Channel;
    conversations?: Conversation[];
    contactId?: string;
    contact?: Contact;
    localVisitorId: string;
    ip?: string;
    city?: string;
    telCode?: string;
    country?: string;
    region?: string;
    userAgent?: string;
    browser?: string;
    browserVersion?: string;
    name?: string;
    email?: string;
    mobile?: number;
    timezone?: string;
    lastSeen: Date;
    visitedPages: VisitedPage[];
    presence: PresenceOptions;
    conversationPresence: PresenceOptions;
}

export interface WebChannel {
    name: string;
    url: string;
    widgetHeadline: string;
    statusTagLine: string;
    token?: string;
    brandColor: string;
    welcomeMessage: string;
    buttonText: string;
    awayMessage: string;
    widgetPosition: {
        placement: "right" | "left";
        marginX: number;
        marginY: number;
    };
    triggerMessage?: string;
    autoTriggerTimer?: number;
    logo?: string;
    allowAnonymousChat: boolean;
    visitorIntroText?: string;
    getName?: boolean;
    getEmail?: boolean;
    getMobile?: boolean;
}

export interface Channel {
    id?: string;
    accountId: string;
    account?: Account;
    assignTo?: User;
    assignToId: string;
    channelType: string;
    web?: WebChannel;
    whatsapp?: unknown;
    createdById: string;
    createdBy?: User;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface Address {
    streetName: string;
    city: string;
    region: string;
    zipCode: string;
    country: string;
}

export interface Company {
    id: string;
    name: string;
    industry: string;
    size: string;
    companyWebsite: string;
    companyLinkedIn: string;
    address: Address;
}

export interface Contact {
    account: string | Account;
    name: string;
    email: string[];
    phone: string[];
    social?: {
        twitter: string;
        facebook: string;
        instagram: string;
    };
    address?: {
        streetName?: string;
        city?: string;
        state?: string;
        zipCode?: string;
        country?: string;
    };
    visitor?: string | Visitor;
    company?: string | Company;
    createdAt?: Date;
    updatedAt?: Date;
    createdBy?: string;
    id?: string;
}

const WEB_MESSAGE_TYPES = ["audio", "document", "image", "location", "text", "video", "voice", "sticker"] as const;
export type WebMessageType = (typeof WEB_MESSAGE_TYPES)[number];

export interface WebMessageText {
    body: string;
}

export interface WebMessageMedia {
    caption?: string;
    // filename on the sender's device
    filename?: string;
    fileId: string;
    mimeType: string;
    path: string;
}

export interface WebMessage {
    type: WebMessageType;
    text?: WebMessageText;
    video?: WebMessageMedia;
    image?: WebMessageMedia;
    audio?: WebMessageMedia;
    document?: WebMessageMedia;
    voice?: WebMessageMedia;
    sticker?: WebMessageMedia;
}

export interface PrivateMessage extends WebMessage {
    mentions: string[];
}

export interface Message {
    id?: string;
    conversationId?: string;
    conversation?: Conversation;
    accountId: string;
    account?: Account;
    channelId: string;
    channel?: Channel;
    channelType: string;
    localMessageId: string;
    userId?: string;
    user?: User;
    contactId?: string;
    contact?: Contact;
    visitor?: Visitor;
    visitorId?: string;
    sender: string;

    web?: WebMessage;
    whatsapp?: unknown;
    private?: PrivateMessage;

    createdAt?: Date;
    updatedAt?: Date;
}

export interface User {
    id: string;
    name?: string;
    picture?: string;
    email: string;
}

export enum StatusOptions {
    OPEN = "OPEN",
    CLOSE = "CLOSE",
}

interface WebConversation {
    visitorId?: string;
    visitor?: Visitor;
    visitorUnreadCount?: number;
}

interface MentionedUser {
    userId: string;
    user?: Pick<User, "name" | "email" | "picture" | "id">;
    lastReadMessageId?: string;
    userlastSeen?: Date;
    userUnreadCount: number;
}

export interface Conversation {
    id?: string;
    accountId: string;
    account?: Account;
    channelId: string;
    channel?: Channel;
    channelType: string;
    contactId?: string;
    contact?: Contact;
    latestMessageId?: string;
    latestMessage?: Message;
    status: StatusOptions;

    userId?: string;
    user?: User;
    userLastSeen?: Date;
    userUnreadCount?: number;

    pinnedBy?: string[];

    web?: WebConversation;
    whatsapp?: unknown;

    lastReadMessageId?: string;
    mentionedUsers?: MentionedUser[];

    createdAt?: Date;
    updatedAt?: Date;
    followup?: string;
}
