import NewMessageForm from "app/components/NewMessageForm";
import { StoreContext } from "app/store";
import {
    Message as IMessage,
    StatusOptions,
    WebChannel,
    WebMessageMedia,
    WebMessageText,
    WebMessageType,
} from "app/types";
import { getFileType } from "app/utils/helpers";
import { parentCall, useInfiniteScroll } from "app/utils/hooks";
import { SocketEvents, useSocket } from "app/utils/use-socketio";
import preact, { Fragment, h } from "preact";
import { StateUpdater, useContext, useEffect, useRef, useState } from "preact/hooks";
import { v4 as uuid } from "uuid";
import MessageList from "./MessageList";
import Message from "./MessageListItem";

const uploadMessageMedia = async (file: File, accountId: string): Promise<string> => {
    try {
        const accessToken = window?.location?.hash?.replace(/#/gi, "");
        const formData = new FormData();
        formData.append("file", file);
        const upload = await fetch(`/api/account/${accountId}/files`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
        });
        const uploadResponse: { uri: string } = await upload.json();
        return uploadResponse.uri;
    } catch (error) {
        console.log(error);
        return "";
    }
};

export const getWelcomeMessage = (channelId: string, accountId: string, welcomeMessage: string): IMessage => {
    return {
        localMessageId: uuid(),
        sender: "sdfsdf",
        channelId,
        accountId,
        channelType: "web",
        web: { type: "text", text: { body: welcomeMessage } },
    };
};

interface MessagesProps {
    onLoadMore: (nextCursor: string) => void;
    onSend: (message: IMessage) => void;
    messages: IMessage[];
    setMessages: StateUpdater<IMessage[]>;
}

const Messages: preact.FunctionalComponent<MessagesProps> = (props) => {
    const { onLoadMore, onSend, messages, setMessages } = props;

    const [isAllLoaded, setIsAllLoaded] = useState<boolean>(false);

    const messageListContainer = useRef<null | HTMLDivElement>(null);
    const store = useContext(StoreContext);

    const { decodedToken, visitor, channelSettings: cs, openConversation, localVisitorId, dispatch } = store;

    const { channelId, accountId } = decodedToken ?? {};

    const channelSettings = cs as WebChannel;
    const { brandColor, awayMessage, welcomeMessage: welcomeMsgText } = channelSettings;

    const visitorId = visitor?.id;
    const me = visitorId ?? localVisitorId;

    const loadMoreData = () => {
        const nextCursor = messages[messages.length - 1]?.id;

        if (!nextCursor || isAllLoaded) return;

        onLoadMore(nextCursor);
    };

    const [isFetching, setIsFetching] = useInfiniteScroll(messageListContainer, loadMoreData);

    const onMessage = (messages: IMessage | IMessage[]) => {
        if (Array.isArray(messages) && messages.length < 10) {
            setIsAllLoaded(true);
        }

        setMessages((prev) => {
            let next = [...prev];

            if (Array.isArray(messages)) {
                next = [...next, ...messages];
            } else {
                next = [messages, ...next];
            }

            return next
                .slice()
                .reverse()
                .filter((v, i, a) => a.findIndex((t) => t.localMessageId === v.localMessageId) === i)
                .reverse();
        });

        if (!Array.isArray(messages)) {
            const { conversation } = messages;

            if (conversation && !openConversation && conversation.status === StatusOptions.OPEN) {
                dispatch({ openConversation: conversation });
            }
        }

        parentCall("setUnreadCount", [0]);

        if (isFetching) setIsFetching(false);
    };

    useSocket(SocketEvents.Message, onMessage);

    useEffect(() => {
        // clear old conversation messages

        // setMessages([]);
        if (messages.length < 10) {
            setIsAllLoaded(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (text?: string, file?: File | null) => {
        try {
            if (!accountId || !channelId) return;

            const type = file ? getFileType(file.type) : "text";

            let textMessage: { text: WebMessageText } | null = null;
            let mediaMessage: Partial<Record<Exclude<"text", WebMessageType>, WebMessageMedia>> | null = null;

            if (type === "text" && text) {
                textMessage = {
                    text: { body: text },
                };
            }

            if (file) {
                const uploadedFilePath = await uploadMessageMedia(file, accountId);
                const filename = uploadedFilePath.substr(uploadedFilePath.lastIndexOf("/") + 1); // File name with file extension
                const fileId = filename.substring(0, filename.lastIndexOf("."));
                const mediaObj: WebMessageMedia = {
                    ...(text ? { caption: text } : {}),
                    filename: file.name,
                    mimeType: file.type,
                    path: uploadedFilePath,
                    fileId,
                };
                mediaMessage = {
                    [type]: mediaObj,
                };
            }

            const myMessage: IMessage = {
                web: {
                    type,
                    ...(textMessage ?? {}),
                    ...(mediaMessage ?? {}),
                },
                visitorId: me,
                accountId,
                channelId,
                conversationId: openConversation?.id,
                localMessageId: uuid(),
                channelType: "web",
                sender: me,
            };

            onMessage(myMessage);
            onSend(myMessage);
        } catch (err) {
            console.log(err);
        }
    };

    if (!channelId || !accountId) return null;

    const welcomeMessage = getWelcomeMessage(channelId, accountId, welcomeMsgText);

    return (
        <Fragment>
            <div className="messages" ref={messageListContainer}>
                <MessageList messages={messages} isLoading={isFetching && !isAllLoaded} />
                {isAllLoaded ? (
                    <Message message={welcomeMessage} me={me} brandColor={brandColor} isSameParticipant={false} />
                ) : null}
            </div>
            <NewMessageForm onSubmit={onSubmit} awayMessage={awayMessage} brandColor={brandColor} />
        </Fragment>
    );
};

export default Messages;
