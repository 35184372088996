import { h, JSX } from "preact";
import { WebMessageProps } from "app/components/Messages/MessageListItem";
import { unescapeHtml } from "app/utils/common";

export const urlify = (text: string): (JSX.Element | string)[] => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part) => {
        if (part.match(urlRegex)) {
            return (
                <a target="_blank" rel="noopener noreferrer" href={part}>
                    {part}
                </a>
            );
        }
        return part;
    });
};

const TextMessage: preact.FunctionalComponent<WebMessageProps> = (props) => {
    const { webMessage } = props;
    const text = unescapeHtml(webMessage?.text?.body);
    if (!text) return null;
    return <div>{urlify(text) || ""}</div>;
};

export default TextMessage;
