import { h, JSX } from "preact";
import { useRef, useState, useEffect, useContext } from "preact/hooks";
import classNames from "classnames";
import { ISocketIOContext, SocketIOContext } from "app/utils/use-socketio";

interface NewMessageFormProps {
    onSubmit: (text?: string, file?: File | null) => void;
    awayMessage?: string;
    disabled?: boolean;
    brandColor: string;
}

const NewMessageForm: preact.FunctionalComponent<NewMessageFormProps> = (props) => {
    const {
        // FIXME: should add logic to show the away message
        // awayMessage,
        disabled,
        brandColor,
    } = props;
    const { connected } = useContext<ISocketIOContext>(SocketIOContext);

    const [text, setText] = useState<string>("");
    const [file, setFile] = useState<File | null>(null);
    const [isInputMounted, setIsInputMounted] = useState<boolean>(true);

    useEffect(() => {
        if (!isInputMounted) {
            setIsInputMounted(true);
        }
    }, [isInputMounted]);

    const onText = (e: JSX.TargetedEvent<HTMLTextAreaElement, Event>) => {
        const value = (e.target as HTMLTextAreaElement)?.value;
        setText(value);
    };

    const formRef = useRef<HTMLFormElement | null>(null);

    const onFileChange = (e: JSX.TargetedEvent<HTMLInputElement, Event>) => {
        const file = (e.target as HTMLInputElement)?.files?.[0];
        if (file) {
            setFile(file);
        }
    };

    const onFileRemove = () => {
        setIsInputMounted(false);
        setFile(null);
    };

    const keyDownHandler = (e: KeyboardEvent) => {
        if (e.key === "Enter" && e.shiftKey === false) {
            e.preventDefault();
            if (formRef.current) {
                formRef.current.dispatchEvent(new Event("submit"));
            }
        }
    };

    const onSubmit = async (e: JSX.TargetedEvent<HTMLFormElement, Event>) => {
        e.preventDefault();
        if (!text.trim() && !file) {
            return;
        }
        props.onSubmit(text.trim(), file);
        setText("");
    };

    return (
        <div className="compose">
            {/*{awayMessage ? <div className="conversation-away-message">{awayMessage}</div> : null}*/}
            {file ? (
                <div className="attachment-container">
                    <div className="icon-wrapper">
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            aria-hidden="true"
                            focusable="false"
                            height="24"
                            width="24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 0 0 174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z" />
                        </svg>
                    </div>
                    <div className="filename">{file.name}</div>
                    <button className="icon-wrapper" onClick={onFileRemove}>
                        <svg
                            stroke="currentColor"
                            fill="none"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            height="16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </div>
            ) : null}
            <form
                className={classNames("compose-container", { disabled })}
                ref={formRef}
                onSubmit={onSubmit}
                autoComplete="off"
            >
                <textarea
                    value={text}
                    onInput={onText}
                    onKeyDown={keyDownHandler}
                    placeholder={connected ? "Enter your message..." : "Connecting..."}
                    tabIndex={1000}
                    disabled={disabled}
                />
                {isInputMounted && (
                    <input
                        onChange={onFileChange}
                        type="file"
                        style={{ display: "none" }}
                        disabled={disabled}
                        id="upload-attachment"
                    />
                )}
                {/* <label className="attachment" htmlFor="upload-attachment">
                    <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        aria-hidden="true"
                        focusable="false"
                        height="24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0 0 12.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 0 0 174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z" />
                    </svg>
                </label> */}
                <button type="submit" className="send" disabled={disabled}>
                    <i className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                            <path
                                fill={connected ? brandColor : "currentColor"}
                                d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"
                            />
                        </svg>
                    </i>
                </button>
            </form>
        </div>
    );
};

export default NewMessageForm;
