import axios from "axios";
import { RefObject } from "preact";
import { StateUpdater, useEffect, useState } from "preact/hooks";

export function parentCall(method: string, args: any[] = []) {
    const data = {
        src: "chatty",
        fn: method,
        args,
    };

    window.parent.postMessage(data, "*");
}

export const useDownloadAttachment = (path?: string, filename?: string) => {
    const [downloadLink, setDownloadLink] = useState<{ name: string; url: string } | null>(null);
    useEffect(() => {
        const getFileBlob = async () => {
            if (!path) return;
            try {
                const response = await axios.get(path, { responseType: "blob" });
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const name = filename ?? path.replace(/^.*[\\\/]/, "");
                setDownloadLink({ url, name });
            } catch (error) {
                console.log(error);
                setDownloadLink({ url: path, name: filename ?? `${new Date().toISOString()}-attachment` });
            }
        };
        getFileBlob();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return downloadLink;
};

export const useInfiniteScroll = (
    element: RefObject<HTMLDivElement | null>,
    callback: () => void
): [boolean, StateUpdater<boolean>] => {
    const [isFetching, setIsFetching] = useState(false);

    function isScrolling() {
        const scrollHeight = element?.current?.scrollHeight ?? 0;
        const scrollTop = Math.abs(element?.current?.scrollTop ?? 0);
        const clientHeight = element?.current?.clientHeight ?? 1;
        const isBottom = scrollHeight - scrollTop >= clientHeight * 0.3;
        if (!isBottom || isFetching) return;
        setIsFetching(true);
    }

    useEffect(() => {
        element.current?.addEventListener("scroll", isScrolling);
        return () => {
            element?.current?.removeEventListener("scroll", isScrolling);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isFetching) return;
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    return [isFetching, setIsFetching];
};
