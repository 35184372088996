import { useDownloadAttachment } from "app/utils/hooks";
import { h } from "preact";
import { WebMessageProps } from "app/components/Messages/MessageListItem";
import DownloadButton from "app/components/DownloadButton";

const StickerMessage: preact.FunctionalComponent<WebMessageProps> = (props) => {
    const { webMessage } = props;
    const path = webMessage?.sticker?.path;
    const name = webMessage?.sticker?.filename;
    const downloadLink = useDownloadAttachment(path, name);
    if (!webMessage) return null;
    return (
        <div className="media-message-wrapper">
            {downloadLink && <DownloadButton {...downloadLink} />}
            <img className="sticker-message" src={webMessage.sticker?.path} />
        </div>
    );
};

export default StickerMessage;
