import { h } from "preact";
import { WidgetActions } from "../App/App";

export interface ConversationHeaderProps {
    headLine: string;
    status: string;
    brandColor: string;
    logo?: string;
    onMinimize: WidgetActions["onMinimize"];
}

const ConversationHeader: preact.FunctionalComponent<ConversationHeaderProps> = (props) => {
    const { brandColor, logo, headLine, status, onMinimize } = props;
    return (
        <div className="message-header" style={{ backgroundColor: brandColor }}>
            {logo ? <img src={logo} alt="" /> : <span className="icon">&#127881;</span>}
            <p>
                <strong className="title">{headLine || "Chat with us!"}</strong>
                <span className="online-status">{status || "We are online now..."}</span>
            </p>
            <button className="close-button" onClick={onMinimize} aria-label="Close the Chat Widget">
                <i className="flex">
                    <svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.502 8.5l7.29 7.29c.277.278.277.727 0 1.003-.137.138-.32.207-.5.207s-.362-.07-.5-.207L8.5 9.503l-7.29 7.29c-.14.138-.32.207-.5.207-.183 0-.364-.07-.502-.207-.277-.276-.277-.725 0-1.002l7.29-7.29-7.29-7.29C-.07.932-.07.483.208.206c.277-.276.725-.276 1 0L8.5 7.497l7.29-7.29c.277-.276.725-.276 1.002 0 .277.277.277.726 0 1.002L9.502 8.5z"
                            fill-rule="evenodd"
                        />
                    </svg>
                </i>
            </button>
        </div>
    );
};

export default ConversationHeader;
