import { createContext } from "preact";

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface ISocketIOContext {
    socket: SocketIOClient.Socket;
    connected: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SocketIOContext = createContext<any>({});
