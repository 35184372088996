import { h } from "preact";
import { useRef, useEffect, useState } from "preact/hooks";
import io from "socket.io-client";
import { SocketIOContext } from "./context";

export interface SocketIOProviderProps {
    url: string;
    opts?: SocketIOClient.ConnectOpts;
    onConnect?: (isConnected: boolean) => void;
}

export const SocketIOProvider: preact.FunctionalComponent<SocketIOProviderProps> = ({
    url,
    opts,
    onConnect,
    children,
}) => {
    const [connected, setConnected] = useState<boolean>(false);
    const connectOpts = Object.assign({ transports: ["websocket"], autoConnect: false }, opts);
    const socketRef = useRef(io(url, connectOpts));

    socketRef.current.on("connect", () => {
        setConnected(socketRef.current.connected);
        if (typeof onConnect === "function") onConnect(socketRef.current.connected);
    });
    socketRef.current.on("error", (error: Error) => {
        console.debug("Socket::error::");
        console.debug(error);
    });
    socketRef.current.on("connect_error", (error: Error) => {
        console.debug("Socket::connect_error::error::");
        console.debug(error);
    });
    socketRef.current.on("connect_timeout", (timeout: number) => {
        console.debug(`Socket::connect_timeout::timeout::${timeout}`);
    });
    socketRef.current.on("reconnect", (attemptNumber: number) => {
        console.debug(`Socket::reconnect::attemptNumber::${attemptNumber}`);
    });
    socketRef.current.on("reconnect_attempt", (attemptNumber: number) => {
        console.debug(`Socket::reconnect_attempt::attemptNumber::${attemptNumber}`);
    });
    socketRef.current.on("reconnect_error", (error: Error) => {
        console.debug("Socket::reconnect_error::error::");
        console.debug(error);
    });
    socketRef.current.on("reconnect_failed", () => {
        console.debug("Socket::reconnect_failed");
    });
    socketRef.current.on("ping", () => {
        console.debug("Socket::ping");
    });
    socketRef.current.on("pong", (latency: number) => {
        console.debug(`Socket::pong::${latency}`);
    });
    socketRef.current.on("disconnect", () => {
        setConnected(socketRef.current.connected);
        if (typeof onConnect === "function") onConnect(socketRef.current.connected);
    });

    useEffect(() => {
        const socket = socketRef.current;
        return socket.close;
    }, [url, opts]);

    return (
        <SocketIOContext.Provider value={{ socket: socketRef.current, connected }}>{children}</SocketIOContext.Provider>
    );
};
