import { useContext, useEffect, useRef, useMemo } from "preact/hooks";
import { ISocketIOContext, SocketIOContext } from "./context";

export enum SocketEvents {
    VisitorConnect = "visitorConnect",

    JoinConversation = "joinConversation",
    LeaveConversation = "leaveConversation",

    LoadMessages = "loadMessages",
    Message = "message",

    JoinPage = "joinPage",
    LeavePage = "leavePage",

    PageVisit = "pageVisit",
    ActionEvent = "actionEvent",
}

export enum ActionEvents {
    AddContact = "addContact",
}

interface UseSocketResult {
    socket: SocketIOClient.Socket;
    unsubscribe: () => void;
    subscribe: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseSocketCallback = (...args: any[]) => void;

export const useSocket = (eventName: SocketEvents, callback: UseSocketCallback): UseSocketResult => {
    const { socket } = useContext<ISocketIOContext>(SocketIOContext);
    const callbackRef = useRef(callback);

    callbackRef.current = callback;

    const eventKey = useMemo(() => {
        return `on${eventName.charAt(0).toUpperCase() + eventName.slice(1)}`;
    }, [eventName]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const socketHandlerRef = useRef((...args: any[]) => {
        if (callbackRef.current) {
            callbackRef.current(...args);
        }
    });

    const subscribe = () => {
        if (eventKey) {
            socket.on(eventKey, socketHandlerRef.current);
        }
    };

    const unsubscribe = () => {
        if (eventKey) {
            socket.off(eventKey, socketHandlerRef.current);
        }
    };

    useEffect(() => {
        subscribe();
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventKey]);

    return { socket, unsubscribe, subscribe };
};
