import { Storage } from "app/types";
import Basil from "basil.js";
import { EventEmitter } from "tiny-events";
import { initialState as defaultState } from "./index";

const basilOptions = {
    // Specify all Basil supported storages and priority order
    storages: ["local", "memory"],
    // Specify the default storage to use
    storage: "local",
};
const basil = new Basil(basilOptions);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type KeyValue = Record<string, any>;

interface StoreConfig {
    localStorageKey: string;
    dontPersist: string[];
}

const defaultStoreConfig: StoreConfig = {
    localStorageKey: "store",
    dontPersist: [],
};

export default class Store extends EventEmitter {
    localStorageKey: string;
    dontPersist: string[];
    localState: Storage;

    constructor(initialState = defaultState, storeConfig = defaultStoreConfig) {
        super();

        this.localStorageKey = storeConfig.localStorageKey;
        this.dontPersist = storeConfig.dontPersist;

        let storedState: Storage;

        try {
            const localStorageState = basil.get(this.localStorageKey);
            storedState = localStorageState ? JSON.parse(localStorageState) : initialState;
        } catch (e) {
            storedState = initialState;
        }

        this.localState = { ...initialState, ...storedState };

        window.addEventListener("storage", (e) => {
            // Cross-tab communication
            if (e.key !== this.localStorageKey) {
                return;
            }

            if (!e.newValue) {
                // The localStorage has been removed
                return location.reload();
            }

            const storedState: Storage = JSON.parse(e.newValue);
            this.setStoredState(storedState);
        });
    }

    get state() {
        return this.localState;
    }

    persist() {
        const persistable: Storage = { ...this.localState };
        for (const ignoredKey of this.dontPersist) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            delete (persistable as any)[ignoredKey];
        }
        basil.set(this.localStorageKey, JSON.stringify(persistable));
    }

    setState(partialState: Partial<Storage>) {
        const prevState = this.localState;
        this.localState = { ...prevState, ...partialState };
        this.persist();
        this.emit("change", this.localState, prevState, partialState);
    }

    setStoredState(storedState: Storage) {
        const prevState = this.localState;

        const nonPeristable: Partial<Storage> = {};
        for (const ignoredKey of this.dontPersist) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (nonPeristable as any)[ignoredKey] = (prevState as any)[ignoredKey];
        }

        this.localState = { ...storedState, ...nonPeristable };
        this.emit("change", this.localState, prevState);
    }
}
