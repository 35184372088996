import Home from "app/components/Home";
import { initialState, StorageContext, StoreContext } from "app/store";
import {
    BasicVisitorInfo,
    PageInfo,
    PresenceOptions,
    Storage,
    Visitor,
    VisitorConnectInfo,
    WebChannel,
    WidgetStatus,
} from "app/types";
import { browserNameVersion, timeZone } from "app/utils/browserInfo";
import { visibility } from "app/utils/helpers";
import { parentCall } from "app/utils/hooks";
import { socketEmit, SocketEvents, useSocket } from "app/utils/use-socketio";
import axios from "axios";
import { createBrowserHistory } from "history";
import { createContext, h } from "preact";
import Router from "preact-router";
import { useContext, useEffect, useState } from "preact/hooks";

export interface WidgetActions {
    onMinimize: () => void;
    onRestore: () => void;
    onOpenWindow: () => void;
}

type WidgetContextType = WidgetStatus &
    WidgetActions & {
        isConnected: boolean;
    };

const history = createBrowserHistory({ basename: process.env.HISTORY_BASENAME });

const initVisitor = (localVisitorId: string, ip: string): BasicVisitorInfo => {
    const userAgent = navigator.userAgent;
    const [browser, browserVersion] = browserNameVersion();
    const timezone = timeZone();
    return {
        localVisitorId,
        ip,
        userAgent,
        browser,
        browserVersion,
        timezone,
    };
};

export const WidgetContext = createContext<WidgetContextType>({
    ...initialState.widgetStatus,
    isConnected: false,
    onMinimize: () => void 0,
    onRestore: () => void 0,
    onOpenWindow: () => void 0,
});

const useParentMessageListener = ({ store, socket }: { store: StorageContext; socket: SocketIOClient.Socket }) => {
    const { visitor } = store;

    const onMessage = (event: MessageEvent) => {
        if (!event.data?.src || event.data.src !== "chatty") {
            return;
        }
        if (event.data.fn === "pageVisited") {
            const pageInfo: PageInfo = event.data.args;
            if (visitor?.id) {
                socketEmit(socket, SocketEvents.PageVisit, visitor.id, pageInfo);
            }
        } else if (event.data.fn === "getWidgetContentSize") {
            if (window?.document?.body) {
                const documentSize = {
                    width: window.document.getElementsByClassName("widget-container")[0].scrollWidth,
                    height: window.document.getElementsByClassName("widget-container")[0].scrollHeight,
                };
                parentCall("postWidgetContentSize", [documentSize]);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("message", onMessage, false);
        return () => window.removeEventListener("message", onMessage, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visitor]);

    return null;
};

const getChannelDetailsReq = async (token: string, accountId: string, channelId: string) => {
    return await axios.get<WebChannel>(
        `${process.env.SERVER_SOCKET_URL}visitor-api/accounts/${accountId}/channels/${channelId}/settings`,
        {
            headers: { Authorization: `Bearer ${token}` },
            withCredentials: true,
        }
    );
};

export const App = () => {
    const [isConnected, setConnected] = useState(false);
    const store = useContext(StoreContext);
    const token = window.location.hash?.substring(1);

    const {
        widgetStatus: { minimized, hasOpenedTheChat },
        dispatch,
        localVisitorId,
        decodedToken,
    } = store;

    const handleVisibilityChange = () => {
        const visible = !visibility.hidden;

        const vId = store?.visitor?.id;

        if (!vId) return;

        if (!visible) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            socketEmit(socket, SocketEvents.LeaveConversation, vId);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            socketEmit(socket, SocketEvents.LeavePage, vId);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            socketEmit(socket, SocketEvents.JoinConversation, vId);
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            socketEmit(socket, SocketEvents.JoinPage, vId);
        }
    };

    const onVisitorConnect = ({ visitor, conversation, channelSettings }: VisitorConnectInfo) => {
        // store server visitor id
        dispatch({ visitor, openConversation: conversation, channelSettings });
        const { widgetPosition } = channelSettings as WebChannel;
        parentCall("ready", [{ widgetPosition }]);
        if ((channelSettings as WebChannel)?.autoTriggerTimer) {
            setTimeout(() => {
                console.log("auto trigger");
                if (minimized && !hasOpenedTheChat) {
                    handleRestore();
                }
            }, ((channelSettings as WebChannel)?.autoTriggerTimer ?? 0) * 1000);
        }
    };

    const updateVisitorInfo = (data: Storage) => {
        dispatch(data);
        socketEmit(socket, SocketEvents.JoinConversation, data.visitor?.id);
    };

    const { socket } = useSocket(SocketEvents.VisitorConnect, updateVisitorInfo);

    // parent window message listener
    useParentMessageListener({ socket, store });

    const handleMinimize = () => {
        parentCall("minimizeWindow");
        dispatch({ widgetStatus: { ...store.widgetStatus, minimized: true } });
    };

    const handleRestore = () => {
        parentCall("restoreWindow");
        dispatch({
            widgetStatus: {
                ...store.widgetStatus,
                minimized: false,
                undocked: false,
                hasOpenedTheChat: true,
            },
        });
    };

    // FIXME: not used anywhere for now
    const handleOpenWindow = () => {
        parentCall("openPopout");
        dispatch({
            widgetStatus: {
                ...store.widgetStatus,
                undocked: true,
            },
        });
    };

    const finalize = () => {
        visibility.removeListener(handleVisibilityChange);
    };

    const initialize = () => {
        parentCall(minimized ? "minimizeWindow" : "restoreWindow");

        visibility.addListener(handleVisibilityChange);
    };

    useEffect(() => {
        const { accountId, channelId } = decodedToken || {};

        if (!accountId || !channelId) return;

        const visitorDetails: Visitor | BasicVisitorInfo = store.visitor ?? initVisitor(store.localVisitorId, store.ip);

        const visitor: Visitor = {
            ...visitorDetails,
            accountId,
            channelId,
            localVisitorId,
            visitedPages: [],
            presence: PresenceOptions.ONLINE,
            conversationPresence: minimized ? PresenceOptions.AWAY : PresenceOptions.ONLINE,
            lastSeen: new Date(),
        };
        initialize();
        const getChannelDetails = async () => {
            try {
                const channelDetails = await getChannelDetailsReq(token, accountId, channelId);
                onVisitorConnect({
                    visitor,
                    channelSettings: channelDetails?.data,
                });
                socketEmit(socket, SocketEvents.VisitorConnect, visitor);
            } catch (err) {
                console.error("Unable to fetch widget details", err);
            }
        };
        getChannelDetails();
        return finalize;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const widgetContext = {
        ...store.widgetStatus,
        isConnected,
        onMinimize: handleMinimize,
        onRestore: handleRestore,
        onOpenWindow: handleOpenWindow,
    };

    // Socket opens only when user clicks on the widget
    useEffect(() => {
        if (!minimized && !socket.connected) {
            socket.open();
            setConnected(true);
        }
    }, [minimized, socket]);

    console.log("isConnected", isConnected);

    return (
        <WidgetContext.Provider value={widgetContext}>
            <Router history={history}>
                <Home path="/" />
            </Router>
        </WidgetContext.Provider>
    );
};
