import { StoreContext } from "app/store";
import { DecodedToken, VisitorToken } from "app/types";
import { getIp } from "app/utils/browserInfo";
import { ISocketIOContext, SocketIOContext } from "app/utils/use-socketio";
import JwtDecode from "jwt-decode";
import { h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { App } from "./App";

const requestIp = async (token: string) => {
    const ip = await getIp();

    let tokenDetails: DecodedToken | null = null;

    try {
        const { id: channelId, accId: accountId, ...decodedToken } = JwtDecode<VisitorToken>(token);
        tokenDetails = {
            channelId,
            accountId,
            ...decodedToken,
        };
    } catch (error) {
        tokenDetails = null;
    }

    return { ip, tokenDetails };
};

export const AppContainer: preact.FunctionalComponent<{ token: string }> = (props) => {
    const [ip, setIp] = useState<string | null>(null);
    const { connected } = useContext<ISocketIOContext>(SocketIOContext);
    const { dispatch } = useContext(StoreContext);

    useEffect(() => {
        requestIp(props.token).then(({ ip, tokenDetails }) => {
            if (!tokenDetails) {
                dispatch({ ip });
                setIp(ip);
                return;
            }
            dispatch({ ip, decodedToken: tokenDetails });
            setIp(ip);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.token]);

    if (!ip) {
        return null;
    }

    return <App />;
};
